import { FC, useEffect } from "react";
// hooks
import { useAppDispatch, useUserGeolocation } from "src/hooks";
// utils
import { getDaysToNow } from "src/utils/time";
// const
import { HIDE_GEOLOCATION_MODAL_EVERYWHERE } from "src/constants/variables";
import { LAST_LOCATION_MODAL_SHOW } from "src/constants/storage";
// redux
import { updateVariable } from "src/redux/slices/variables";

const DAYS_TO_SHOW_LOCATION_MODAL = 30;

const UpdateReduxOnLoad: FC = () => {
  const { getGeolocationPermissionStatus, getUserCoordinates, setDefaultCoordinates } =
    useUserGeolocation();
  const dispatch = useAppDispatch();

  const handleGeoModal = () => {
    const lastShow = localStorage.getItem(LAST_LOCATION_MODAL_SHOW);
    if (!lastShow) return;

    let isHidden: boolean = true;
    if (getDaysToNow(new Date(lastShow)) >= DAYS_TO_SHOW_LOCATION_MODAL) {
      isHidden = false;
    }
    dispatch(updateVariable({ name: HIDE_GEOLOCATION_MODAL_EVERYWHERE, value: isHidden }));
  };

  /** GEOLOCATION START */
  useEffect(() => {
    // get geolocation permission on mount
    if (navigator.permissions) {
      getGeolocationPermissionStatus().then((permission) => {
        if (permission === "granted") {
          // if geolocation available, update redux state center to user coordinates
          getUserCoordinates();
        } else {
          setDefaultCoordinates();
        }
        handleGeoModal();
      });
    } else {
      setDefaultCoordinates();
      handleGeoModal();
    }
  }, []);
  /** GEOLOCATION FINISH */

  return null;
};

export default UpdateReduxOnLoad;
