// @ts-ignore - this is shim, should be compatible with String.replaceAll
import replaceAll from "string.prototype.replaceall";
import "./style.css";
import React, { useEffect } from "react";
// libs
import type { AppProps } from "next/app";
import { css, createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import NProgress from "nprogress";
import Router, { useRouter } from "next/router";
import { initAmplitude, setAmlitudeUserProperties } from "src/libs/amplitude";
// components
import UpdateReduxOnLoad from "src/components/containers/UpdateReduxOnLoad";
// modals
import ModalMenu from "src/components/ui/Modals/Menu";
import ModalSearchFilter from "src/components/ui/Modals/SearchFilter";
import ModalLightbox from "src/components/ui/Modals/Lightbox";
import ModalGeolocation from "src/components/ui/Modals/Geolocation";
// redux
// import { store } from 'src/redux/store'
import { useStore } from "src/redux/store";
// hooks
import { useWindowSize } from "src/hooks";
// styles
import normalize from "normalize.css";
// theme
import { colors, nprogressOverrides } from "src/theme";
// lib styles
import RPIStyles from "react-phone-input-2/lib/high-res.css";
import MapboxStyles from "mapbox-gl/dist/mapbox-gl.css";
import SlickDefault from "slick-carousel/slick/slick.css";
import SlickTheme from "slick-carousel/slick/slick-theme.css";
import RILightboxStyles from "react-image-lightbox/style.css";

// we need to do this in order to be able to use replaceAll on SSR
replaceAll.shim();

const libsStyles = css`
  ${normalize};
  ${nprogressOverrides};
  ${RPIStyles};
  ${MapboxStyles};
  ${SlickDefault};
  ${SlickTheme};
  ${RILightboxStyles};
`;

const GlobalStyle = createGlobalStyle`
  ${libsStyles};

  html, body, #__next {
    color: ${colors.greyDark};
    caret-color: ${colors.main};
  }

  a {
    color: ${colors.main};
    text-decoration: none;
  }

  @font-face {
  font-family: "Cooper";
  src: url("/fonts/Cooper Md BT Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
  @font-face {
  font-family: "Brown";
  src: url("/fonts/BrownLLWeb-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
  @font-face {
  font-family: "Brown";
  src: url("/fonts/BrownLLWeb-Italic.woff") format("woff");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
  @font-face {
  font-family: "Brown";
  src: url("/fonts/BrownLLWeb-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
  @font-face {
  font-family: "Brown";
  src: url("/fonts/BrownLLWeb-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
`;
require("dotenv").config();

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const { width } = useWindowSize();
  const store = useStore(pageProps.initialReduxState);

  useEffect(() => {
    initAmplitude();
    setAmlitudeUserProperties();
  }, []);

  const renderModalMenu = width < 768;
  const renderModalSearchFilter = pathname === "/veterinarian/[...area]";
  const renderModalLightbox = pathname === "/clinic/[id]";
  const renderModalGeolocation =
    pathname === "/" ||
    pathname === "/veterinarian/[...area]" ||
    pathname === "/appointment/request";

  return (
    <>
      <GlobalStyle />

      <Provider store={store}>
        <UpdateReduxOnLoad />

        <Component {...pageProps} />

        {renderModalMenu && <ModalMenu />}
        {renderModalSearchFilter && <ModalSearchFilter />}
        {renderModalLightbox && <ModalLightbox />}
        {renderModalGeolocation && <ModalGeolocation />}
      </Provider>
    </>
  );
}

export default MyApp;
